<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- search input -->
          <div class="custom-search d-flex justify-content-end">
            <b-col md="6">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Procurar..."
                    type="text"
                    class="d-inline-block"
                  />
                  <!-- <label class="ml-1 mr-1">Pesquisar</label> -->
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="ml-1"
                    variant="primary"
                    @click="addLocalTrabalho()"
                  >
                    Adicionar
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </div>
          <!-- table -->
          <b-card-actions
            ref="loadingCard"
            no-actions
          >
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm
              }"
              :select-options="{
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength
              }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Name -->
                <span v-if="props.column.field === 'nomeCliente'">
                  <span>{{ props.row.nomeCliente }}</span>
                </span>
                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <span>
                    <a
                      v-b-tooltip.hover.bottom="'Detalhes'"
                      :href="'/localTrabalho/visualizar/' + props.row.id"
                      class="icone"
                    >
                      <feather-icon icon="FileTextIcon" />
                    </a>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <!-- <b-dropdown-item
                        :to="{
                          name: 'visualizar-trabalho',
                          params: { idDoLocal: props.row.id }
                        }"
                      >
                        <feather-icon icon="FileTextIcon" />
                        <span class="align-middle ml-50">Detalhes</span>
                      </b-dropdown-item> -->

                      <b-dropdown-item
                        :to="{
                          name: 'editar-trabalho',
                          params: { idDoLocal: props.row.id }
                        }"
                      >
                        <feather-icon
                          icon="Edit2Icon"
                          class="mr-50"
                        />
                        <span>Editar</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="showModal(props.row.id)">
                        <feather-icon
                          icon="TrashIcon"
                          class="mr-50"
                        />
                        <span>Excluir</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Mostrando 1 até
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '30']"
                      class="mx-1"
                      @input="
                        value => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      de {{ props.total }} registros
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        value => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                Nenhum registro encontrado!
              </div>
            </vue-good-table>
          </b-card-actions>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BRow,
  BCol,
  BBadge,
  VBTooltip,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem
} from "bootstrap-vue"
import { VueGoodTable } from "vue-good-table"
import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
import store from "@/store/index"
import axios from "@axios"
import { methods } from "vue-echarts"
import Ripple from "vue-ripple-directive"
import { getUserData } from "@/auth/utils"
import useJwt from "@/auth/jwt/useJwt"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

// import DeleteModal from "./modal-delete-user.vue"

export default {
  components: {
    VueGoodTable,
    BButton,
    BCard,
    BCardActions,
    BRow,
    BCol,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem
    // DeleteModal
  },
  directives: { Ripple, "b-tooltip": VBTooltip },

  data() {
    return {
      boxTwo: "",
      idDoPerfil: 0,
      idLocalTrabalho: 0,
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: "Cliente",
          field: "nomeCliente"
        },
        {
          label: "Local de Trabalho",
          field: "titulo"
        },
        {
          label: "Município",
          field: "municipio"
        },
        {
          label: "Bairro",
          field: "bairro"
        },
        {
          label: "Ações",
          field: "action"
        }
      ],
      // colunaGeral: [
      //   // { label: "Cliente", field: "nomeCliente" },
      //   { label: "Local deTrabalho", field: "titulo" },
      //   { label: "Município", field: "municipio" },
      //   { label: "Bairro", field: "bairro" },
      //   { label: "Ações", field: "action" }
      // ],

      rows: [],
      searchTerm: ""
    }
  },
  computed: {},
  mounted() {
    this.$refs["loadingCard"].showLoading = true
    this.idDoPerfil = Number(getUserData().idDoPerfil)
    this.removeColumns()
    this.getListaBase()
  },
  created() {
    // this.$http.get("http://localhost:3000/exemplo").then(res => {
    // this.rows = res.data.rows
    // console.log("response: ", res.data)
    // })
  },
  methods: {
    addLocalTrabalho() {
      this.$router.push("/localTrabalho/cadastrar")
    },
    removeColumns() {
      if (this.idDoPerfil != 1) {
        this.columns.splice("field", 1)
      }
    },
    async getListaBase() {
      if (this.idDoPerfil === 1) {
        const resp = await useJwt
          .listarLocal()
          .then(response => response.data)
          .catch(response => response.data)
        if (resp.sucesso) {
          // console.log("resposne Master: ", resp.dados.rows)
          this.rows = resp.dados.rows
        }
      } else {
        const resp = await useJwt
          .listarLocalPorCliente()
          .then(response => response.data)
          .catch(response => response.data)
        if (resp.sucesso) {
          // console.log("response Outros: ", resp.dados.rows)
          this.rows = resp.dados.rows
        }
      }
      this.$refs["loadingCard"].showLoading = false
    },
    async InativarBase(id) {
      this.idLocalTrabalho = id
      if (this.idLocalTrabalho != 0) {
        const id = this.idLocalTrabalho
        const resp = await useJwt
          .DesativarBaseById(id)
          .then(response => response.data)
          .catch(response => {
            const catchError = Object.assign({}, response)
            this.MensagemErroExcluirBase(
              "danger",
              "top-center",
              catchError.response.status
            )
          })
        if (resp.sucesso) {
          this.MensagemBaseExcluida("success", "top-center")
          setTimeout(() => {
            this.getListaBase()
          }, 2000)
        }
      } else {
        return this.MensagemPermissaoBase("warning", "top-center")
      }
    },

    // editarLocalTrabalho() {
    //   this.$router.push("/localTrabalho/cadastro")
    // },
    // Função resonponsavel por abrir e fchar o modal
    showModal(id) {
      this.idLocalTrabalho = 0
      this.$bvModal
        .msgBoxConfirm("Deseja excluir esse Local de Trabalho?", {
          title: "Confirmar a exclusão",
          size: "sm",
          okVariant: "primary",
          okTitle: "SIM",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          // aqui vai o codigo para delete ou cancel
          if (value == true) {
            this.InativarBase(id)
          }
        })
    },
    MensagemBaseExcluida(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Base Excluída!",
            icon: "EditIcon",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemPermissaoBase(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Desculpe, você não tem permissão para excluir essa Base!",
            icon: "CoffeeIcon",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemErroExcluirBase(variant, position, mensagem) {
      const nomeDoUsuario = getUserData().nomeDoUsuario
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Erro ao Excluir Base, Por favor verifique sua conexão!",
            icon: "ErrorIcon",
            text: mensagem,
            variant
          }
        },
        {
          position
        }
      )
    }
  }
}
</script>
<style scoped>
.icone {
  color: inherit;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
