<template>
  <TrabalhoList />
</template>

<script>
export default {
  components: {
    // BCol,
    // BContainer,
    TrabalhoList
  },
  data() {
    return {
      form: {
        cliente: "",
        localTrabalho: "",
        municipio: "",
        bairro: ""
      }
    }
  }
}
import TrabalhoList from "./TrabalhoList"
import { BCol, BContainer } from "bootstrap-vue"
</script>

<style></style>
